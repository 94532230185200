import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Game} from '../../game';
import {GameService} from '../../game.service';
@Component({
  selector: 'hpf-game-edit',
  templateUrl: './game-edit.component.html'
})
export class GameEditComponent implements OnInit, OnDestroy {
  /** The game */
  game = new Game();
  /** The game */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private gameService: GameService) {}
  /** Init */
  ngOnInit() {
    // Get the game after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch game from API */
  private refresh() {
    this.gameService.get(this.id, { _populate: ['*'] })
      .then((game: Game) => {
        this.game.fromObject(game.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the game is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the game is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/game')
      .catch((error) => this.errorService.handle(error));
  }
}
