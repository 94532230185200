import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	OnDestroy,
	Output,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	ValidatorFn,
	AbstractControl,
} from '@angular/forms';
import { Subject, Subscription as RxJsSubscription } from 'rxjs';
import {
	ErrorService,
	ExplainErrorsService,
	SessionService,
} from '@app/services';
import { environment } from '@env/environment';
import { Plateau } from '../plateau';
import { PlateauService } from '../plateau.service';
import { LanguageTabComponent } from '@app/models/language';
import {
	tileLayer,
	latLng,
	Marker as sMarker,
	Icon,
	FeatureGroup,
	featureGroup,
	DrawEvents,
	LatLng,
	latLngBounds,
	Polyline,
	Layer,
	Marker,
} from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { MarkerService } from '@app/models/marker/marker.service';
import { MarkerSearchParams } from '@app/models/marker/marker-search-params';
import { RouteService } from '@app/models/route/route.service';
import { RouteSearchParams } from '@app/models/route/route-search-params';
import { RoutePoint } from '@app/models/route/route';
@Component({
	selector: 'hpf-plateau-form',
	templateUrl: './plateau-form.component.html',
})
export class PlateauFormComponent implements OnInit, OnDestroy {
	private subscriptions: RxJsSubscription[] = [];
	/** The model subscription */
	private modelSubscription: RxJsSubscription;
	/** The plateau to inject in the form */
	@Input() plateau: Plateau;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<Plateau>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<void>();
	/** Called the delete button is clicked */
	@Output() delete = new EventEmitter<void>();
	/** Set loading state of the form */
	@Input() loading = false;
	/** Enable the deletion button */
	@Input() deletable = true;
	/** Enabled quick form */
	@Input() quickFormEnabled = environment.plugins.quickForm.enabled;
	/** Denotes if deltion in progress */
	deleting = false;
	/** Show delete modal */
	deleteModal = false;

	publishModal = false;

	moveToPublicModal = false;

	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Check permission of update **/
	readOnly = false;
	@ViewChildren(LanguageTabComponent) languageTabs: QueryList<
		LanguageTabComponent
	>;

	icon = new Icon({
		iconSize: [25, 41],
		iconAnchor: [13, 41],
		iconUrl: 'assets/marker-icon.png',
		shadowUrl: 'assets/marker-shadow.png',
	});
	mapDisplay = false;
	mapOptions = {
		layers: [
			tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				minZoom: 1,
				attribution: '...',
			}),
		],
		zoom: 12,
		center: new LatLng(46.879966, -121.726909),
		maxBounds: latLngBounds(new LatLng(90, -168), new LatLng(-90, 190)),
	};
	mapDrawnItems: FeatureGroup = featureGroup();
	mapMarkerItems: FeatureGroup = featureGroup();
	mapDrawOptions = {
		position: 'topleft',
		draw: {
			marker: false,
			polyline: false,
			circle: false,
			rectangle: false,
			polygon: false,
			circlemarker: false,
		},
	};
	locationFormUpdate = new Subject();

	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private plateauService: PlateauService,
		private sessionService: SessionService,
		private markerService: MarkerService,
		private routeService: RouteService,
		private http: HttpClient //
	) {}
	/** Init */
	async ngOnInit() {
		this.readOnly = !(await this.sessionService.checkPermission(
			'plateau',
			'update'
		));

		this.subscriptions.push(
			this.locationFormUpdate.pipe(debounceTime(1000)).subscribe(() => {
				this.updateModel();
				this.updateMap();
			})
		);

		// Init model
		if (!this.plateau) {
			this.plateau = new Plateau();
		}

		// Create form
		this.form = this.formBuilder.group({
			name: new FormControl(this.plateau.props.name, [
				Validators.required,
			]),

			languages: new FormControl(this.plateau.props.languages, [
				Validators.required,
			]),
			min_lat: new FormControl(this.plateau.props.min_lat, [
				Validators.min(-90),
				Validators.max(90),
				Validators.required,
			]),
			max_lat: new FormControl(this.plateau.props.max_lat, [
				Validators.min(-90),
				Validators.max(90),
				Validators.required,
				this.biggerThan('min_lat'),
			]),
			min_lng: new FormControl(this.plateau.props.min_lng, [
				Validators.min(-180),
				Validators.max(180),
				Validators.required,
			]),
			max_lng: new FormControl(this.plateau.props.max_lng, [
				Validators.min(-180),
				Validators.max(180),
				Validators.required,
				this.biggerThan('min_lng'),
			]),
			logo: new FormControl(this.plateau.props.logo, []),
			image_intro: new FormControl(this.plateau.props.image_intro, [
				Validators.required,
			]),

			audio_descriptions: new FormControl(
				this.plateau.props.audio_descriptions,
				[]
			),
		});
		setTimeout(() => this.initMap(), 500);
		// Update form when model loads or changes
		this.modelSubscription = this.plateau.subscribe(() => {
			this.updateForm();
			setTimeout(() => this.initMap(), 500);
		});
	}
	biggerThan(other: string): ValidatorFn {
		return (control: AbstractControl): { key: string } | null =>
			control.value > this.form?.get(other).value
				? null
				: { key: `${control} must be bigger than ${other}` };
	}

	/** Destroy */
	ngOnDestroy() {
		this.modelSubscription.unsubscribe();
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			for (let element of this.languageTabs) {
				await element.submit();
			}
			// Creation or update ?
			if (this.plateau.isNew()) {
				// Creation
				const plateau: Plateau = await this.plateauService.create(
					this.plateau.toPayload()
				);
				this.create.next(plateau);
			} else {
				// Update
				await this.plateauService.update(
					this.plateau.getId(),
					this.plateau.toPayload()
				);
				this.update.next();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}

	private updateMap() {
		this.mapDrawnItems.clearLayers();
		var line = [];
		try {
			line.push(
				new LatLng(
					this.plateau.props.min_lat,
					this.plateau.props.min_lng
				)
			);
			line.push(
				new LatLng(
					this.plateau.props.max_lat,
					this.plateau.props.min_lng
				)
			);
			line.push(
				new LatLng(
					this.plateau.props.max_lat,
					this.plateau.props.max_lng
				)
			);
			line.push(
				new LatLng(
					this.plateau.props.min_lat,
					this.plateau.props.max_lng
				)
			);
			line.push(
				new LatLng(
					this.plateau.props.min_lat,
					this.plateau.props.min_lng
				)
			);
			var poly = new Polyline(line);
			poly.setStyle({ color: '#000000' });
			this.mapDrawnItems.addLayer(poly);

			this.mapOptions.center = new LatLng(
				(this.plateau.props.min_lat + this.plateau.props.max_lat) / 2,
				(this.plateau.props.min_lng + this.plateau.props.max_lng) / 2
			);
		} catch (error) {}
	}

	private async initMap() {
		this.mapDisplay = true;
		if (!this.plateau.isNew()) {
			var $this = this;
			(
				await this.markerService.list(
					new MarkerSearchParams({
						_page: 0,
						_limit: 100,
						plateau: this.plateau.getId(),
					}).toObject()
				)
			).items.map((mdata) => {
				const latLng = new LatLng(
					mdata.props.latitude,
					mdata.props.longitude
				);
				const marker = new Marker(latLng, {
					icon: this.icon,
					title: mdata.props.label,
				});
				$this.mapMarkerItems.addLayer(marker);
			});

			(
				await this.routeService.list(
					new RouteSearchParams({
						plateau: this.plateau.getId(),
						_page: 0,
						_limit: 100,
					}).toObject()
				)
			).items.map((routedata) => {
				const latLngs = this.pointsToLatLngs(routedata.props.itinerary);
				var poly = new Polyline(latLngs);
				poly.setStyle({ color: '#' + routedata.props.color });
				this.mapMarkerItems.addLayer(poly);
			});
		}
		this.updateMap();
	}

	pointsToLatLngs(points: RoutePoint[]): LatLng[] {
		return points.map(
			(point) => new LatLng(point.latitude, point.longitude)
		);
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.plateau.props[key] = this.form.get(key).value;
		}
	}
	/** Update inputs values from models properties */
	private updateForm(): void {
		this.form.setValue({
			name: this.plateau.props.name,

			languages: this.plateau.props.languages,
			min_lat: this.plateau.props.min_lat,
			max_lat: this.plateau.props.max_lat,
			min_lng: this.plateau.props.min_lng,
			max_lng: this.plateau.props.max_lng,
			logo: this.plateau.props.logo,
			image_intro: this.plateau.props.image_intro,

			audio_descriptions: this.plateau.props.audio_descriptions,
		});
	}
	public assetBundleStatus = {};
	public createAssetBundleAvailable(): boolean {
		return !this.assetBundleStatus['status'];
	}
	getAssetBundleStatus() {
		this.http
			.get(`${environment.api.uri}/assetbundle/status`)
			.subscribe((data) => {
				this.assetBundleStatus = data;

				this.assetBundleStatus['isSame'] =
					this.plateau.props._id ==
					this.assetBundleStatus['plateauId'];
			});
	}
	async moveToPublic(): Promise<void> {
		try {
			await this.plateauService.moveToPublic(this.plateau);
			window.location.reload();
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async createAssetBundle(): Promise<void> {
		try {
			await this.plateauService.createAssetBundle(this.plateau);
			window.location.reload();
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	/** Called on deletion */
	onDelete(): void {
		this.deleting = true;
		this.plateauService
			.remove(this.plateau.getId())
			.then(() => {
				this.delete.next();
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => (this.deleting = false));
	}
}
