import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModuleLoad } from '@app/translate-import';
import { ModelsRoutingModule } from './models-routing.module';
import { ModelsModule } from './models.module';
import { AboutService } from './about';
import { AboutComponent } from './about/about/about.component';
import { AboutCreateComponent } from './about/views/about-create/about-create.component';
import { AboutEditComponent } from './about/views/about-edit/about-edit.component';
import { AboutListComponent } from './about/views/about-list/about-list.component';
import { AudioService } from './audio';
import { AudioComponent } from './audio/audio/audio.component';
import { AudioCreateComponent } from './audio/views/audio-create/audio-create.component';
import { AudioEditComponent } from './audio/views/audio-edit/audio-edit.component';
import { AudioListComponent } from './audio/views/audio-list/audio-list.component';
import { AudioDescriptionService } from './audio-description';
import { AudioDescriptionComponent } from './audio-description/audio-description/audio-description.component';
import { AudioDescriptionCreateComponent } from './audio-description/views/audio-description-create/audio-description-create.component';
import { AudioDescriptionEditComponent } from './audio-description/views/audio-description-edit/audio-description-edit.component';
import { AudioDescriptionListComponent } from './audio-description/views/audio-description-list/audio-description-list.component';
import { CategoryService } from './category';
import { CategoryComponent } from './category/category/category.component';
import { CategoryCreateComponent } from './category/views/category-create/category-create.component';
import { CategoryEditComponent } from './category/views/category-edit/category-edit.component';
import { CategoryListComponent } from './category/views/category-list/category-list.component';
import { CreditService } from './credit';
import { CreditComponent } from './credit/credit/credit.component';
import { CreditCreateComponent } from './credit/views/credit-create/credit-create.component';
import { CreditEditComponent } from './credit/views/credit-edit/credit-edit.component';
import { CreditListComponent } from './credit/views/credit-list/credit-list.component';
import { DiaporamaService } from './diaporama';
import { DiaporamaComponent } from './diaporama/diaporama/diaporama.component';
import { DiaporamaCreateComponent } from './diaporama/views/diaporama-create/diaporama-create.component';
import { DiaporamaEditComponent } from './diaporama/views/diaporama-edit/diaporama-edit.component';
import { DiaporamaListComponent } from './diaporama/views/diaporama-list/diaporama-list.component';
import {GameService} from './game';
import {GameComponent} from './game/game/game.component';
import {GameCreateComponent} from './game/views/game-create/game-create.component';
import {GameEditComponent} from './game/views/game-edit/game-edit.component';
import {GameListComponent} from './game/views/game-list/game-list.component';
import { ImageService } from './image';
import { ImageComponent } from './image/image/image.component';
import { ImageCreateComponent } from './image/views/image-create/image-create.component';
import { ImageEditComponent } from './image/views/image-edit/image-edit.component';
import { ImageListComponent } from './image/views/image-list/image-list.component';
import { ImageCreateBulkComponent } from './image/views/image-create-bulk/image-create-bulk.component';
import { LanguageService } from './language';
import { LanguageComponent } from './language/language/language.component';
import { LanguageCreateComponent } from './language/views/language-create/language-create.component';
import { LanguageEditComponent } from './language/views/language-edit/language-edit.component';
import { LanguageListComponent } from './language/views/language-list/language-list.component';
import { MarkerService } from './marker';
import { MarkerComponent } from './marker/marker/marker.component';
import { MarkerCreateComponent } from './marker/views/marker-create/marker-create.component';
import { MarkerEditComponent } from './marker/views/marker-edit/marker-edit.component';
import { MarkerListComponent } from './marker/views/marker-list/marker-list.component';
import { MarkerBulkCreateComponent } from './marker/views/marker-create-bulk/marker-create-bulk.component';
import { PlateauService } from './plateau';
import { PlateauComponent } from './plateau/plateau/plateau.component';
import { PlateauCreateComponent } from './plateau/views/plateau-create/plateau-create.component';
import { PlateauEditComponent } from './plateau/views/plateau-edit/plateau-edit.component';
import { PlateauListComponent } from './plateau/views/plateau-list/plateau-list.component';
import { PointOfInterestService } from './point-of-interest';
import { PointOfInterestComponent } from './point-of-interest/point-of-interest/point-of-interest.component';
import { PointOfInterestCreateComponent } from './point-of-interest/views/point-of-interest-create/point-of-interest-create.component';
import { PointOfInterestEditComponent } from './point-of-interest/views/point-of-interest-edit/point-of-interest-edit.component';
import { PointOfInterestListComponent } from './point-of-interest/views/point-of-interest-list/point-of-interest-list.component';
import { RouteService } from './route';
import { RouteComponent } from './route/route/route.component';
import { RouteCreateComponent } from './route/views/route-create/route-create.component';
import { RouteEditComponent } from './route/views/route-edit/route-edit.component';
import { RouteListComponent } from './route/views/route-list/route-list.component';
import { TextTranslatedService } from './text-translated';
import { TextTranslatedComponent } from './text-translated/text-translated/text-translated.component';
import { TextTranslatedCreateComponent } from './text-translated/views/text-translated-create/text-translated-create.component';
import { TextTranslatedEditComponent } from './text-translated/views/text-translated-edit/text-translated-edit.component';
import { TextTranslatedListComponent } from './text-translated/views/text-translated-list/text-translated-list.component';
import { UserService } from './user';
import { UserComponent } from './user/user/user.component';
import { UserCreateComponent } from './user/views/user-create/user-create.component';
import { UserEditComponent } from './user/views/user-edit/user-edit.component';
import { UserListComponent } from './user/views/user-list/user-list.component';
import { VideoService } from './video';
import { VideoComponent } from './video/video/video.component';
import { VideoCreateComponent } from './video/views/video-create/video-create.component';
import { VideoEditComponent } from './video/views/video-edit/video-edit.component';
import { VideoListComponent } from './video/views/video-list/video-list.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		TranslateModuleLoad(),
		ModelsModule,
		ModelsRoutingModule,
	],
	declarations: [
		AboutComponent,
		AboutCreateComponent,
		AboutEditComponent,
		AboutListComponent,
		AudioComponent,
		AudioCreateComponent,
		AudioEditComponent,
		AudioListComponent,
		AudioDescriptionComponent,
		AudioDescriptionCreateComponent,
		AudioDescriptionEditComponent,
		AudioDescriptionListComponent,
		CategoryComponent,
		CategoryCreateComponent,
		CategoryEditComponent,
		CategoryListComponent,
		CreditComponent,
		CreditCreateComponent,
		CreditEditComponent,
		CreditListComponent,
		DiaporamaComponent,
		DiaporamaCreateComponent,
		DiaporamaEditComponent,
		DiaporamaListComponent,
		GameComponent,
		GameCreateComponent,
		GameEditComponent,
		GameListComponent,
		ImageComponent,
		ImageCreateComponent,
		ImageEditComponent,
		ImageListComponent,
		ImageCreateBulkComponent,
		LanguageComponent,
		LanguageCreateComponent,
		LanguageEditComponent,
		LanguageListComponent,
		MarkerComponent,
		MarkerCreateComponent,
		MarkerEditComponent,
		MarkerListComponent,
		MarkerBulkCreateComponent,
		PlateauComponent,
		PlateauCreateComponent,
		PlateauEditComponent,
		PlateauListComponent,
		PointOfInterestComponent,
		PointOfInterestCreateComponent,
		PointOfInterestEditComponent,
		PointOfInterestListComponent,
		RouteComponent,
		RouteCreateComponent,
		RouteEditComponent,
		RouteListComponent,
		TextTranslatedComponent,
		TextTranslatedCreateComponent,
		TextTranslatedEditComponent,
		TextTranslatedListComponent,
		UserComponent,
		UserCreateComponent,
		UserEditComponent,
		UserListComponent,
		VideoComponent,
		VideoCreateComponent,
		VideoEditComponent,
		VideoListComponent,
	],
	providers: [
		AboutService,
		AudioService,
		AudioDescriptionService,
		CategoryService,
		CreditService,
		DiaporamaService,
		GameService,
		ImageService,
		LanguageService,
		MarkerService,
		PlateauService,
		PointOfInterestService,
		RouteService,
		TextTranslatedService,
		UserService,
		VideoService,
	],
	exports: [
		AboutComponent,
		AudioComponent,
		AudioDescriptionComponent,
		CategoryComponent,
		CreditComponent,
		DiaporamaComponent,
		GameComponent,
		ImageComponent,
		LanguageComponent,
		MarkerComponent,
		PlateauComponent,
		PointOfInterestComponent,
		RouteComponent,
		TextTranslatedComponent,
		UserComponent,
		VideoComponent,
	],
})
export class ModelsViewsModule {}
