import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {GameSearchParams} from '../game-search-params';
import {GameService} from '../game.service';
import {Game} from '../game';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-game-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class GameSelectComponent extends EntitySelectComponent< Game> {
  protected modelName = 'game';
  /**
   * Constructor
   * @param {GameService} gameService
   * @param {ErrorService} errorService
   */
  constructor(private gameService: GameService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Game)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Game[]>this.model).push(<Game>model);
          } else {
            this.model = <Game>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Game[]> {
      const params = new GameSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      return (await this.gameService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Game> {
      return await this.gameService.get(id);
  }
}
