import {Injectable} from '@angular/core';
import {Game, GameInterface, GamePayload} from './game';
import {GameSearchParamsInterface} from './game-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class GameService extends BaseModelService< Game, GameInterface, GamePayload, GameSearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'game';
  }
  /** @inheritDoc */
  protected new(object: GameInterface): Game {
    return new Game(object);
  }
}
