import {Component, Input, OnInit} from '@angular/core';
import {ErrorService} from '@app/services';
import {GameSearchParams} from '../game-search-params';
import {GameService} from '../game.service';
@Component({
  selector: 'hpf-game-count',
  templateUrl: './game-count.component.html'
})
export class GameCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: GameSearchParams = new GameSearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private gameService: GameService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.gameService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
