import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Game} from '../../game';

@Component({
  selector: 'hpf-game-create',
  templateUrl: './game-create.component.html'
})
export class GameCreateComponent implements OnInit {
  /** The game */
  game = new Game();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the game is created */
  onCreate(game: Game): void {
    this.router.navigateByUrl(`/game/${game.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
