import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
export interface GameInterface extends BaseModelInterface {
  name: string;
  key_code: string;
  created_at: number | Date;
}
export interface GamePayload {
  name: string;
  key_code: string;
}
type GamePayloadKey = keyof GamePayload;
export class Game extends BaseModel< GameInterface, GamePayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Populate the current instance from an object */
  fromObject(object: GameInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): GameInterface {
    const props = Object.assign({}, this.props);
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): GamePayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as GameInterface);
    return payload as GamePayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): GamePayloadKey[] {
  	return [
      'name',
      'key_code',
  	];
  }
}
