import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hpf-game',
  templateUrl: './game.component.html'
})
export class GameComponent implements OnInit {

  /** Constructor */
  constructor() {}

  /** Init */
  ngOnInit() {}

}
