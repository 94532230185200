import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {GameSearchParams} from '../game-search-params';

@Component({
  selector: 'hpf-game-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class GameListTopBarComponent extends EntityListTopBarComponent< GameSearchParams> {
}
 