import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Plateau, PlateauInterface } from '../plateau/plateau';

import { Marker, MarkerInterface } from '../marker/marker';
export interface RoutePoint {
	latitude: number;
	longitude: number;
}
export interface RouteInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	name: string;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	descriptions?: (string | TextTranslated | TextTranslatedInterface)[];
	plateau: string | Plateau | PlateauInterface;
	markers?: (string | Marker | MarkerInterface)[];
	duration: number;
	color: string;
	published: boolean;
	itinerary?: RoutePoint[];
	deleted: boolean;
}
export interface RoutePayload {
	name: string;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	descriptions?: (string | TextTranslated | TextTranslatedInterface)[];
	plateau: string | Plateau | PlateauInterface;

	markers?: (string | Marker | MarkerInterface)[];
	duration: number;
	color: string;
	published: boolean;
	itinerary?: RoutePoint[];
}
type RoutePayloadKey = keyof RoutePayload;
export class Route extends BaseModel<RouteInterface, RoutePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of descriptions has been populated */
	descriptionsExists(): boolean {
		return (
			!!this.props &&
			this.props.descriptions instanceof Array &&
			(<TextTranslated[]>this.props.descriptions).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of plateau has been populated */
	plateauExists(): boolean {
		return (
			!!this.props &&
			this.props.plateau instanceof Plateau &&
			this.props.plateau.exists()
		);
	}
	/** Denotes if the instance of point of interests has been populated */
	MarkersExists(): boolean {
		return (
			!!this.props &&
			this.props.markers instanceof Array &&
			(<Marker[]>this.props.markers).every((item) => {
				return item instanceof Marker && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: RouteInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.descriptions instanceof Array) {
			this.props.descriptions = (<TextTranslatedInterface[]>(
				this.props.descriptions
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (typeof this.props.plateau === 'object') {
			this.props.plateau = new Plateau(
				<PlateauInterface>this.props.plateau
			);
		}
		if (this.props.markers instanceof Array) {
			this.props.markers = (<MarkerInterface[]>this.props.markers).map(
				(item) => {
					return typeof item === 'object' ? new Marker(item) : item;
				}
			);
		}

		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): RouteInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		if (typeof props.descriptions === 'undefined') {
			props.descriptions = [];
		}
		if (typeof props.markers === 'undefined') {
			props.markers = [];
		}
		if (typeof props.itinerary === 'undefined') {
			props.itinerary = [];
		}

		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.descriptions instanceof Array) {
			props.descriptions = (<TextTranslated[]>props.descriptions).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		if (props.plateau instanceof Plateau) {
			props.plateau = props.plateau.toObject();
		}
		if (this.props.markers instanceof Array) {
			props.markers = (<Marker[]>props.markers).map((item) => {
				return item instanceof Marker ? item.toObject() : item;
			});
		}

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): RoutePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as RouteInterface);
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		payload.descriptions = payload.descriptions
			? payload.descriptions.map((i) => this.extractId(i))
			: null;
		payload.plateau = payload.plateau
			? this.extractId(payload.plateau)
			: null;
		payload.markers = payload.markers
			? payload.markers.map((i) => this.extractId(i))
			: null;
		return payload as RoutePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): RoutePayloadKey[] {
		return [
			'name',
			'title',
			'descriptions',
			'plateau',
			'markers',
			'duration',
			'color',
			'published',
			'itinerary',
		];
	}
}
